
	import {
		Vue,
		Component,
		Ref
	} from "vue-property-decorator";
	import E from "wangeditor";
	import axios from 'axios';
	import vm from "@/main";
	@Component({
		name: "BasicForm",
	})
	export default class BasicForm extends Vue {
		@Ref("editor") editorRef: any;
		@Ref("cascader1") cascader1: any;
		@Ref("cascader2") cascader2: any;
		private userData = [];
		private dialogVisible = false;
		private dialogImageUrl = '';
		private value = [];
		private options = [];
		private Materialslist = []; //原材料
		private token = "";
		private speclist = [];
		private loading = false;
		private loading2 = false;
		private attrlist = [{}];
		private imageHash = "";
		private imageHashs = [];
		private loadingedit = false;
		private cat3_id = "";
		private img_arr = [{
			name: '',
			url: this.$Apiurl
		}];
		private form = {
			rankWeight: 99, //商品排序
			channel: ["饿了么外卖"],
			name: "",
			description: '',
			imageHash: "",
			imageHashs: "",
			region: "",
			date1: "",
			date2: "",
			delivery: false,
			resource: "",
			desc: "",
			categoryId: 0, //商品分类Id
			lscateId: [],
			stdCategoryId: [], //类目ID
			minPurchaseQuantity: 2, //商品最小起购量
			mainMaterials: [{
				"id": 1000,
				"name": "鸡蛋"
			}], //原材料
		};
		private specData = [{
			name: '', //规格名称
			price: '', //商品价格
			stock: '', //库存量
			packingFee: '', //打包费
			extendCode: '', //商品扩展码
			barCode: '', //商品条形码
			weight: '', //值范围 1-50000
			unit_val: '克',
			packageShare: '',
			unit: '', //份量单位
			maxStock: '' //包装盒份数
		}];
		private catelist = [{}];
		private cate_id = '';
		private editor: any = null;
		//克,千克,毫升,厘米,米,个,串,枚,粒,块,只,副,卷,片,贯,碗,杯,袋,瓶,盒,包,锅,罐,扎,条,人份
		private units = [{
			value: '选项1',
			label: '克'
		}, {
			value: '选项2',
			label: '千克'
		}, {
			value: '选项3',
			label: '毫升'
		}, {
			value: '选项4',
			label: '厘米'
		}, {
			value: '选项5',
			label: '米'
		}, {
			value: '选项6',
			label: '个'
		}, {
			value: '选项7',
			label: '串'
		}, {
			value: '选项8',
			label: '枚'
		}, {
			value: '选项9',
			label: '粒'
		}, {
			value: '选项10',
			label: '块'
		}, {
			value: '选项11',
			label: '只'
		}, {
			value: '选项12',
			label: '副'
		}, {
			value: '选项13',
			label: '卷'
		}, {
			value: '选项14',
			label: '片'
		}, {
			value: '选项15',
			label: '贯'
		}, {
			value: '选项16',
			label: '碗'
		}, {
			value: '选项17',
			label: '杯'
		}, {
			value: '选项18',
			label: '袋'
		}, {
			value: '选项19',
			label: '瓶'
		}, {
			value: '选项20',
			label: '盒'
		}, {
			value: '选项21',
			label: '包'
		}, {
			value: '选项22',
			label: '锅'
		}, {
			value: '选项23',
			label: '罐'
		}, {
			value: '选项24',
			label: '扎'
		}, {
			value: '选项25',
			label: '条'
		}, {
			value: '选项26',
			label: '人份'
		}];
		private unit_val = '';
		private fileList = [];
		private id = 0;
		created() {
			if (this.$route.query.id) {
				var id = parseInt(this.$route.query.id.toString());
				if (id > 0) {
					this.loadingedit = true;
					this.id = id;
					this.getdetail();
				}
			}
			console.log("this.$route.query.id", this.$route.query.id)
			this.attrlist.splice(0, 1);
			this.img_arr.splice(0, 1);
			const item = {
				name: '', //规格名称
				price: '', //商品价格
				stock: '', //库存量
				imageHash: "", //图片imageHash
				imageHashs: "", //图片imageHashs
				packingFee: '', //打包费
				extendCode: '', //商品扩展码
				barCode: '', //商品条形码
				weight: '', //值范围 1-50000
				unit_val: '克',
				packageShare: '',
				unit: '', //份量单位
				maxStock: '' //包装盒份数
			};
			let array = [];
			array.push(item);
			this.specData = array;
			this.fetchUserData();
			this.getcatelist();
			this.getlscate();
		}
		private shopData1 = [];
		private keywords = "";
		private getshoplist() {
			const formData1 = new FormData()
			formData1.append('type', "3")
			formData1.append('keywords', this.keywords)
			formData1.append('act', "list")
			formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
			const url = 'http://www.vssite.com/api/getShop.aspx'
			axios.post(url, formData1).then(data => {
				if (data.data.ret != 0) {
					console.log("sss")
				} else {
					this.shopData1 = data.data.data;
				}
				console.log('data', data)
			}).catch(response => {
				console.log(response)
			})
		}

		private quxiao() {
			this.$router.go(-1)
		}
		private getdetail() {
			const formData1 = new FormData()
			formData1.append('id', this.id.toString())
			formData1.append('act', "detail")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			axios.post(this.$Apiurl + '/api/goods.aspx', formData1)
				.then(response => {
					this.loadingedit = false;
					if (response.data.error) {
						vm.$message({
							showClose: true,
							message: response.data.error.message,
							type: 'warning'
						});
					} else {
						this.form = response.data.data;
						this.fileList = JSON.parse(response.data.data.image);
						this.specData = JSON.parse(response.data.data.specs);
						if (response.data.data.channel != "") {
							if (response.data.data.channel.includes("饿了么外卖")) {
								this.showwm = true;
							} else {
								this.showwm = false;
							}
							if (response.data.data.channel.includes("饿了么零售")) {
								this.showls = true;
							} else {
								this.showls = false;
							}
							this.form.channel = JSON.parse(response.data.data.channel);
						}
						if (response.data.data.wm_cate != "") {
							this.form.stdCategoryId = JSON.parse(response.data.data.wm_cate);
						}
						if (response.data.data.stdCategoryProperty != "") {
							this.speclist = JSON.parse(response.data.data.stdCategoryProperty);
							console.log("this.speclist", response.data.data.stdCategoryProperty)
						}
						if (response.data.data.ls_cate != "") {
							this.form.lscateId = JSON.parse(response.data.data.ls_cate);
						}
						if (response.data.data.sku_property != "") {
							this.ls_speclist = JSON.parse(response.data.data.sku_property);
						}
						if (response.data.data.imageHashs != "") {
							this.imageHashs = response.data.data.imageHashs.split(",");
						}
						if (response.data.data.attributes != "") {
							this.attrlist = JSON.parse(response.data.data.attributes);
						}
					}
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			console.log("dsd");
		}
		mounted() {
			const editor = new E(this.editorRef);
			// 配置 onchange 回调函数，将数据同步到 vue 中
			editor.config.onchange = (newHtml: string) => {
				console.log("newHtml:", newHtml);
				this.handle(newHtml);
			};
			// 创建编辑器
			editor.create();
			editor.txt.html(""); // 重新设置编辑器内容
			this.editor = editor;
		}
		private showwm = true;
		private showls = false;
		private handleCheckAllChange(val: []) {
			var text = val.join(",");
			if (val.length >= 0) {
				if (text.includes("饿了么外卖")) {
					this.showwm = true;
				} else {
					this.showwm = false;
				}
				if (text.includes("饿了么零售")) {
					this.showls = true;
				} else {
					this.showls = false;
				}
			}
			console.log("Checkval", val)
		}
		private catechange(val: string) {
			this.cate_id = val;
			console.log("val", val);
		}
		private getcatelist() {
			const formData1 = new FormData()
			formData1.append('act', "list")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			const url = this.$Apiurl + '/api/addGoodsCategory.aspx'
			axios.post(url, formData1).then(data => {
				if (data.data.ret != 0) {
					console.log("sss")
				} else {
					this.catelist = data.data.data;
					this.Materialslist = data.data.Materials;
				}
				console.log('data', data)
			}).catch(response => {
				console.log(response)
			})
		}
		private addattr() {
			const item = {
				name: '',
				values: [{
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}, {
					val: ''
				}]
			};
			var attrlist = this.attrlist.concat(item);
			this.attrlist = attrlist;
		}
		private addspec() {
			const item = {
				act: "add",
				name: '', //规格名称
				price: '', //商品价格
				stock: '', //库存量
				packingFee: '', //打包费
				extendCode: '', //商品扩展码
				barCode: '', //商品条形码
				weight: '', //值范围 1-50000
				unit_val: '克',
				packageShare: '',
				unit: '', //份量单位
				maxStock: '' //包装盒份数
			};
			var specData = this.specData.concat(item);
			this.specData = specData;
			console.log("this.specData", this.specData);
		}
		private delspec(index: number, row: []) {
			if (this.specData.length > 1) {
				this.specData.splice(index, 1);
			}
			console.log("vindex", index);
			console.log("row", row);
		}
		private delattr(index: number, row: []) {
			if (this.attrlist.length > 0) {
				this.attrlist.splice(index, 1);
			}
			console.log("vindex", index);
			console.log("attrlistrow", this.attrlist);
		}

		private upload(param: {
			file: ''
		}) {
			const formData = new FormData();
			var that = this.$data;
			formData.append('Filedata', param.file)
			formData.append('token', this.$Cookies.get("vue_elm_token"))
			const url = this.$Apiurl + '/tools/upload_ajax.ashx?action=uploadproFile'
			axios.post(url, formData).then(data => {
				that.imageHashs.push(data.data.data.result);
				that.fileList.push({
					name: data.data.imgurl,
					url: this.$Apiurl + data.data.imgurl
				});
				that.img_arr.push({
					name: data.data.imgurl,
					url: this.$Apiurl + data.data.imgurl
				});
				console.log('上传图片成功', that.imageHash)
			}).catch(response => {
				console.log('图片上传失败', response)
			})
		}

		private handleChange(value: string) {
			var nodesInfo = this.cascader1.getCheckedNodes();
			console.log('nodesInfo>>>>>', nodesInfo[0].label)
			const formData1 = new FormData()
			formData1.append('name', nodesInfo[0].label)
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			this.loading = true;
			axios.post(this.$Apiurl + '/api/getBackCategoryPropertyV2.aspx', formData1)
				.then(response => {
					if (response.data.error) {
						vm.$message({
							showClose: true,
							message: response.data.error.message,
							type: 'warning'
						});
					} else {
						this.speclist = response.data.result;
					}
					this.loading = false;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			console.log(value);
		}

		private ls_speclist = [];
		private handleChange1(value: string) {
			var nodesInfo = this.cascader2.getCheckedNodes();
			console.log('nodesInfo>>>>>', nodesInfo[0].label);
			//this.get_child(nodesInfo[0].value);
			const formData1 = new FormData()
			formData1.append('name', nodesInfo[0].label)
			formData1.append('type', "retail")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			this.loading2 = true;
			axios.post(this.$Apiurl + '/api/getBackCategoryPropertyV2.aspx', formData1)
				.then(response => {
					console.log("response", response)
					if (response.data.body.errno == 0) {
						this.ls_speclist = response.data.body.data;
						console.log("this.ls_speclist", this.ls_speclist)
					} else {
						vm.$message({
							showClose: true,
							message: response.data.error.message,
							type: 'warning'
						});
						console.log("dsds")
					}
					this.loading2 = false;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			console.log(value);
		}

		private get_child(parent_id: number) {
			const formData1 = new FormData()
			formData1.append('parent_id', parent_id.toString())
			formData1.append('act', "get_child_list")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			axios.post(this.$Apiurl + '/api/getBackCategoryV2.aspx', formData1)
				.then(response => {
					console.log("response", response)
					if (response.data.body.errno == 0) {
						this.ls_speclist = response.data.body.data;
						console.log("this.ls_speclist", this.ls_speclist)
					} else {
						vm.$message({
							showClose: true,
							message: response.data.error.message,
							type: 'warning'
						});
						console.log("dsds")
					}
					this.loading2 = false;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});

			console.log("sdds");
		}

		private handleRemove(file: {
			name: ''
		}, fileList: []) {
			var img_arr = this.img_arr;
			var ind = 0;
			img_arr.forEach(function(item, index) {
				if (file.name == item.name) {
					console.log("indexindex", index); //输出数组的每一个元素
					ind = index;
				}
				console.log(file.name); //输出数组的每一个元素
			});
			this.img_arr.splice(ind, 1);
			this.fileList.splice(ind, 1);

			console.log("iiii", ind);
			console.log("file", file);
			console.log("fileList", fileList);
		}
		private handlePictureCardPreview(file: {
			url: ''
		}) {
			console.log(file);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		}
		private handleClose(done: boolean) {
			console.log("ddd")
		}
		private cateid = 0;
		private ls_cate = [];
		private drawer = false;
		private selshopnum = 0;
		private shop_mul_sel = [];
		private shopSelectionChange(val: []) {
			this.shop_mul_sel = val;
			this.selshopnum = this.shop_mul_sel.length;
			console.log("this.multipleSelection", this.shop_mul_sel)
		}
		private getlscate() {
			const formData1 = new FormData()
			formData1.append('parentId', this.cateid.toString())
			formData1.append('act', "list")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			axios.post(this.$Apiurl + '/api/getBackCategoryV2.aspx', formData1)
				.then(response => {
					this.ls_cate = response.data;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		}
		private fetchUserData() {
			console.log("xxxxxxxxxx")
			axios.get('https://jsonplaceholder.typicode.com/users/1')
				.then(response => {
					this.userData = response.data;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
			axios.get(this.$Apiurl + '/api/gettoken.aspx')
				.then(response => {
					var jsonparse = response.data;
					console.error('jsonparse!', jsonparse);
					if (jsonparse.ret == 1) {
						//  window.location.href = jsonparse.url;
					} else {
						this.$Cookies.set("vue_elm_token", jsonparse.token, {
							expires: 1
						});
					}
					// this.token = jsonparse.token;
					this.token = this.$Cookies.get("vue_elm_token");
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
			axios.get(this.$Apiurl + '/api/getBackCategoryV2.aspx')
				.then(response => {
					this.options = response.data;
				})
				.catch(error => {
					console.error('There was an error!', error);
				});
		}
		private onSubmit(type: number) {
			console.log('this.id', this.id)
			if (this.form.name == "") {
				vm.$message({
					showClose: true,
					message: '请填写商品名称',
					type: 'warning'
				});
				return;
			}
			if (this.form.description == "") {
				vm.$message({
					showClose: true,
					message: '请填写商品描述',
					type: 'warning'
				});
				return;
			}
			if (this.imageHashs.length <= 0) {
				vm.$message({
					showClose: true,
					message: '请上传商品图片',
					type: 'warning'
				});
				return;
			}
			if (this.form.categoryId == null) {
				vm.$message({
					showClose: true,
					message: '请选择分类',
					type: 'warning'
				});
				return;
			}
			if (this.form.stdCategoryId.length <= 0) {
				vm.$message({
					showClose: true,
					message: '请选择商品类目',
					type: 'warning'
				});
				return;
			}
			var len = this.form.stdCategoryId.length;
			var stdCategoryId = this.form.stdCategoryId[len - 1];
			var lslen = this.form.lscateId.length;
			var cat3_id = this.form.lscateId[lslen - 1];
			var isspec = true;
			var specData = this.specData;
			specData.forEach(function(item, index) {
				console.log(item); //输出数组的每一个元素
				if (item["name"] == "") {
					vm.$message({
						showClose: true,
						message: '请填写规格' + (index + 1) + '名称',
						type: 'warning'
					});
					isspec = false;
					return;
				}
				if (item["price"] == "") {
					vm.$message({
						showClose: true,
						message: '请填写规格' + (index + 1) + '商品价格',
						type: 'warning'
					});
					isspec = false;
					return;
				}
				if (item["stock"] == "") {
					vm.$message({
						showClose: true,
						message: '请填写规格' + (index + 1) + '商品库存',
						type: 'warning'
					});
					isspec = false;
					return;
				}
			})
			if (!isspec) {
				return;
			}
			var speclist = this.speclist;
			if (speclist.length > 0) {
				speclist.forEach(function(item, index) {
					console.log(item); //输出数组的每一个元素
					if (item['required']) {
						if (!item['val']) {
							vm.$message({
								showClose: true,
								message: '请填写/选择' + item['propertyName'],
								type: 'warning'
							});
							return;
						}
					}
				})
			}
			if (type == 2) {
				if (this.selshopnum <= 0) {
					this.getshoplist();
					this.drawer = true;
					return;
				}
			}
			if (type == 3) {
				if (this.selshopnum <= 0) {
					vm.$message({
						showClose: true,
						message: '至少选择一个店铺',
						type: 'warning'
					});
					return;
				}
			}
			const params = this.form;
			const formData3 = new FormData()
			formData3.append('params', JSON.stringify(params))
			formData3.append('categoryId', this.form.categoryId.toString())
			formData3.append('stdCategoryId', stdCategoryId)
			formData3.append("specs", JSON.stringify(this.specData))
			formData3.append("attr", JSON.stringify(this.speclist))
			formData3.append("ls_attr", JSON.stringify(this.ls_speclist))
			formData3.append("attributes", JSON.stringify(this.attrlist))
			formData3.append("imageHashs", this.imageHashs.join(","));
			formData3.append("imageHash", this.imageHashs[0]);
			formData3.append("image", JSON.stringify(this.fileList));
			formData3.append('ls_cate', JSON.stringify(this.form.lscateId))
			formData3.append('wm_cate', JSON.stringify(this.form.stdCategoryId))
			formData3.append('cat3_id', cat3_id)
			formData3.append('id', this.id.toString())
			formData3.append('act', "add")
			formData3.append('token', this.$Cookies.get("vue_elm_token"))
			axios.post(this.$Apiurl + '/api/goods.aspx', formData3).then(data => {
				if (type == 3) {
					var shop_mul_sel = this.shop_mul_sel;
					var goodsids: Array < any > = [];
					var shopids: Array < any > = [];
					shop_mul_sel.forEach(function(item: {
						id: number
					}, index: number) {
						if (item.id > 0) {
							shopids.push({
								id: item.id
							});
						}
					})
					goodsids.push({
						id: data.data.data.id
					})
					this.acttb(goodsids, shopids);
				}
				if (data.data.ret != 0) {
					console.log("sss")
				} else {
					vm.$message({
						showClose: true,
						message: '保存成功',
						type: 'success'
					});
					setTimeout(function() {
						//vm.$router.go(-1);
						console.log("ddd")
					}, 2000)
					this.shoplist = data.data.data;
				}
				console.log('data', data)
			}).catch(response => {
				console.log(response)
			})
			console.log("speclist", speclist);
			console.log("this.$route.params.parameterName", this.$route.query)
			console.log(this.form);
		}

		private acttb(goodsids: Array < any > , shopids: Array < any > ) {
			const loading = this.$loading({
				lock: false,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			const formData1 = new FormData()
			formData1.append('shopids', JSON.stringify(shopids))
			formData1.append('goodsids', JSON.stringify(goodsids))
			formData1.append('act', "tongbu")
			formData1.append('token', this.$Cookies.get("vue_elm_token"))
			const url = 'http://www.vssite.com/api/synchronization.aspx'
			axios.post(url, formData1).then(data => {
				if (data.data.ret != 0) {
					console.log("sss")
				}
				setTimeout(function() {
					loading.close();
				}, 200)
				this.$message({
					showClose: true,
					type: 'success',
					message: '操作成功'
				});
				this.drawer = false;
				console.log('data', data)
			}).catch(response => {
				console.log(response)
			})
			console.log("ddd")
		}
	}
